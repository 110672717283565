<footer>
    <div class="container">
        <hr>
        <div class="row">
            <div class="col-md-4 sec1">
                <img src={{response?.companyMainDetails.imgUrl}} alt="">
                <h4>{{response?.companyMainDetails.phoneNumber}}</h4>
                <p>{{translate.data.footer.connect}}</p>
                <p><a>{{response?.companyMainDetails.emailAddress}}</a></p>
                <p>{{translate.data.footer.connect}}: {{response?.companyMainDetails[translate.data.api.address]}}</p>
            </div>
            <div class="col-md-4 sec2">
                <h6>{{translate.data.footer.impLinks}}</h6>
                <div class="row">
                    <div class="col-6">
                        <ul>
                            <li><a routerLink="/" routerLinkActive="active">
                              {{translate.data.Header.home}}
                                </a></li>
                            <li><a routerLink="/about-us" routerLinkActive="active">
                              {{translate.data.Header.about}}
                                </a></li>
                                <div *ngFor="let linkName of linkNames">
                                  <li class="nav-item" >
                                      <a  class="nav-link pad" routerLink="{{'series/' +linkName?.link}}"  routerLinkActive="active" >
                                             {{linkName[translate.data.api.aboutFeatures]}}
                                      </a>
                                  </li>
                               </div>
                            <!-- <li><a routerLink="" routerLinkActive="active">
                              {{translate.data.Header.commercial}}
                                </a></li>
                            <li><a routerLink="" routerLinkActive="active">
                              {{translate.data.Header.homeChain}}</a></li>
                            <li><a routerLink="" routerLinkActive="active">
                              {{translate.data.Header.special}}
                                </a></li> -->
                        </ul>
                    </div>
                    <div class="col-6">
                        <ul>
                            <!-- <li><a href="#clients" (click)="checkRoute()">
                                    {{translate.data.footer.clients}}
                                </a></li> -->
                            <li><a pageScroll href="#blog" (click)="checkRoute()">
                              {{translate.data.Header.blog}}
                                </a></li>
                        </ul>
                    </div>
                </div>
            </div>
            <div class="col-md-4 sec3">
                <h6>{{translate.data.Header.contact}}</h6>
                <p></p>
                <form #contactForm="ngForm" (ngSubmit)="onSubmit(contactForm.value)">
                    <input type="text" class="form-control" placeholder={{translate.data.contact.name}} name="name" ngModel>
                    <input type="email" class="form-control" placeholder={{translate.data.contact.email}} name="emailAddress"
                        ngModel>
                    <textarea class="form-control" rows="4" placeholder={{translate.data.contact.mesg}} name="message" ngModel></textarea>
                    <button class="btn c-btn" type="submit">{{translate.data.contact.send}}</button>
                </form>
            </div>
        </div>
    </div>
</footer>
<ngx-spinner bdColor="rgba(0, 0, 0, 0.8)" size="medium" color="#eee" type="ball-clip-rotate-pulse" [fullScreen]="true"
    name="loader">
    <p style="color: white">
      {{translate.data.contact.wait}} </p>
</ngx-spinner>
