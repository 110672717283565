<div class="open-video">
    <video muted loop autoplay src={{commercial?.companyMainDetails.videoUrl}} id="myVideo">
    </video>
    <div class={{className}}>
        <h1 class="mainTitle"> {{commercial?.companyMainDetails[translate.data.Home.video.mainTitle]}}</h1>
        <h3 class="sub-title">{{commercial?.companyMainDetails[translate.data.Home.video.subTitle]}}</h3>


        <!-- <form class="footer-container">
            <div class="d-flex playaButton">
                <p>{{translate.data.Home.video.watch}}</p>
                <img class="" id="myBtn" src={{videoBtn}} alt="play-button" (click)="handleVideo()">
            </div>
        </form> -->
    </div>
</div>
