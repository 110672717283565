<div *ngIf="commercial?.clientsVotings.length > 0" class="clients"  id="clients">
    <div class="container">
        <div class="row">
            <div class="col-md-3">
                <h3>{{translate.data.clients}}</h3>
                <p></p>
            </div>
            <div class="col-md-9">
                <owl-carousel-o [options]="customOptions">
                    <ng-template carouselSlide *ngFor="let client of commercial?.clientsVotings">
                        <div class="card client-card">
                            <div class="card-body">
                              <star-rating [value]="client?.rate"
                                [totalstars]="5"
                                checkedcolor="#ed6c37"
                                uncheckedcolor="rgb(224, 224, 224)"
                                size="24px"
                                [readonly]="true">
                              </star-rating>
                                <p>{{client[translate.data.api.clientDesc]}}</p>
                            </div>
                            <img src={{client?.imgUrl}} alt="">
                            <h5>{{client?.name}}</h5>
                            <h6>{{client?.jobTitle}}</h6>
                        </div>
                    </ng-template>
                    <!-- <ng-template carouselSlide>
                        <div class="card client-card">
                            <div class="card-body">
                                <p>تستخدم بشكل رئيسي في المدارس والتعليم والتدريب" المؤسسات ، وما إلى ذلك لتحسين تجربة
                                    العملاء ، وتحسين القوى العاملة ، والتحويل استقبال ترحيب العملاء ، والتفاعل الصوتي "
                                    شرح
                                    وتوجيه وما إلى ذلك.</p>
                            </div>
                            <img src="assets/home/client.png" alt="">
                            <h5>اسم العميل</h5>
                            <h6>الوظيفة</h6>
                        </div>
                    </ng-template> -->
                    <!-- <ng-template carouselSlide>
                        <div class="card client-card">
                            <div class="card-body">
                                <p>تستخدم بشكل رئيسي في المدارس والتعليم والتدريب" المؤسسات ، وما إلى ذلك لتحسين تجربة
                                    العملاء ، وتحسين القوى العاملة ، والتحويل استقبال ترحيب العملاء ، والتفاعل الصوتي "
                                    شرح
                                    وتوجيه وما إلى ذلك.</p>
                            </div>
                            <img src="assets/home/client.png" alt="">
                            <h5>اسم العميل</h5>
                            <h6>الوظيفة</h6>
                        </div>
                    </ng-template> -->
                </owl-carousel-o>
            </div>
        </div>
    </div>
</div>
