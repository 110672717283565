<div class="about-us">
    <div class="header">
      <h1>{{translate.data.about}}</h1>
    </div>
    <img class="lines" src="../../assets/Path 45141.svg" alt="">
    <div class="container">
        <!-- <div class="row">
            <div class="offset-md-4"></div>
            <div class="col-12 pad">
              <div class="cont">
                <h1>{{translate.data.about}}</h1>
              </div>
            </div>
            <div class="offset-md-4"></div>
        </div> -->
        <div class="row">
            <div class="offset-md-2"></div>
            <div class="col-md-8">

            </div>
            <div class="offset-md-2"></div>
        </div>
        <div class="row">
            <div class="col-md-6">
              <p class="title">{{data?.companyMainDetails[translate.data.api.about]}}</p>
                <!-- <div class="desc"><p  *ngFor="let feature of data?.companyMainDetails.aboutUsFeatuers">{{feature[translate.data.api.aboutFeatures]}}</p></div> -->
            </div>
            <div class="col-md-6">
                <img class="img" src={{data?.companyMainDetails.aboutUs_Image}} alt="">
            </div>
        </div>
    </div>
</div>
