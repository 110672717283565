<div class="blog" id="blog">
    <div class="container">
        <div class="row">
            <div *ngIf="blog.length > 0" class="col-md-7 lay order-lg-1 order-md-1 order-2">
                <div >
                      <p-carousel [value]="blog" [numVisible]="2" [numScroll]="2" [autoplayInterval]="3000" >
                        <ng-template let-article pTemplate="item">
                          <div class="blog-card card" (click)="handleArticle(article)">
                            <img src={{article?.imgUrl}} alt="">
                            <div class="card-body">
                                <!-- <div class="line"></div> -->
                                <h5>{{article[translate.data.Home.blog.articleTitle]}}</h5>
                                <p>{{article[translate.data.Home.blog.articleD]}}</p>
                            </div>
                        </div>
                        </ng-template>
                      </p-carousel>


                    <!-- <div class="col-md-6 lay2">
                        <div class="blog-card2 card">
                            <img src={{commercial?.blog[0].imgUrl}}  alt="">
                            <div class="card-body">
                                <div class="line"></div>
                                <h5>{{commercial?.blog[0][translate.data.Home.blog.articleTitle]}}</h5>
                                <p>{{commercial?.blog[0][translate.data.Home.blog.articleDesc]}}</p>
                            </div>
                        </div>
                    </div> -->
                </div>
            </div>
            <div class="col-md-5 order-lg-2 order-md-2 order-1 ">
                <div class="blog-detail">
                    <h3>{{translate.data.Home.blog.title}}</h3>
                    <p>{{commercial?.companyMainDetails[translate.data.api.blog]}}</p>
                    <button class="btn blog-btn" (click)="goToContact()">
                       {{translate.data.Home.blog.more}}
                    </button>
                    <img class="b1" src="assets/home/blog1.svg" alt="">
                    <img class="b2" src="assets/home/blog2.svg" alt="">
                </div>
            </div>
        </div>
    </div>
</div>
