import { Component } from '@angular/core';
import { RobotService } from './Services/robot.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'iCityRobots';
  response:any;
  titles:any;
  linkNames:Array<any>;

  constructor(private route: Router, private robotService: RobotService,) {}

  ngOnInit(): void {
    this.getData();
  }

  getData(){
    this.robotService.getData().subscribe(
      (res) => {
        console.log('done');
        this.response = res;
        this.titles = this.response.seriesTypes;
        console.log("res",this.titles);
        this.linkNames = [...this.titles]
        for(let title of this.linkNames){
          let name = title.name_En.replace(/\s+/g, '');
          title.link = name;
          for(let subTitle of title.commericalSeries){
            let subName = subTitle.nameEn.replace(/\s+/g, '');
            subTitle.link = subName;
          }
        }
        console.log("new ",this.linkNames);

      },
      (error) => {
        console.log(error);
      }
    );
  }
}

