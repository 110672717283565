<div *ngIf="data?.robot.length == 0">
  <div class="commercial">
    <div class="container">
      <div class="row ">
        <div class="col-lg-4 col-md-6 col-12">
          <div class="card-cont">
            <div class={{translate.data.api.bg}}></div>
            <!-- <img class="card-img" src="../../../assets/commercial/Rectangle 2994.svg" alt=""> -->
            <p class="main-title">{{name}}</p>
            <h2 class="sub-title">{{data[translate.data.api.name]}}</h2>
            <!-- <h3>{{robot[translate.data.api.title]}}</h3>
            <p class="desc">{{robot[translate.data.api.desc]}}</p> -->
            <div class="btn-cont">
              <p>{{translate.data.order}}</p>
              <img class={{translate.data.classes.robotBtn}} (click)="goToContact()" src="../../../assets/commercial/Group 10706.svg" alt="">
            </div>
          </div>
        </div>
        <div class="col-lg-8 col-md-6 col-12">
          <!-- <owl-carousel-o class="commertial" [options]="customOptions">
            <ng-template carouselSlide >
                <div class="robot-card">
                    <img src="assets/commercial/Layer 2 (2).svg" alt="">
                    <img class="img-org" src={{data?.imgUrl}} alt="">
                </div>
            </ng-template>
            <ng-template carouselSlide>
                <div class="robot-card">
                  <img src="assets/commercial/Layer 2 (2).svg" alt="">
                  <img class="img-org" src={{data?.imgUrl}} alt="">
                </div>
            </ng-template>
            <ng-template carouselSlide>
                <div class="robot-card">
                  <img src="assets/commercial/Layer 2 (2).svg" alt="">
                  <img class="img-org" src={{data?.imgUrl}} alt="">
                </div>
            </ng-template>
        </owl-carousel-o> -->
        <div class="robot-card">
          <!-- <img src="assets/commercial/Layer 2 (2).svg" alt=""> -->
          <img class="img-org" src={{data?.imgUrl}} alt="">
      </div>
        </div>
      </div>
    </div>
  </div>
</div>



<div class="commercial" *ngFor="let robot of data?.robot">
  <div class="container">
    <div class="row ">
      <div class="col-lg-4 col-md-6 col-12">
        <div class="card-cont">
          <div class={{translate.data.api.bg}}></div>
          <!-- <img class="card-img" src="../../../assets/commercial/Rectangle 2994.svg" alt=""> -->
          <p class="main-title">{{name}}</p>
          <h2 class="sub-title">{{data[translate.data.api.name]}}</h2>
          <h3>{{robot[translate.data.api.title]}}</h3>
          <p class="desc">{{robot[translate.data.api.desc]}}</p>
          <div class="btn-cont">
            <p>{{translate.data.order}}</p>
            <img class={{translate.data.classes.robotBtn}} (click)="goToContact()" src="../../../assets/commercial/Group 10706.svg" alt="">
          </div>
        </div>
      </div>
      <div class="col-lg-8 col-md-6 col-12 ">
        <!-- <owl-carousel-o class="commertial" [options]="customOptions">
          <ng-template carouselSlide >
              <div class="robot-card">
                  <img src="assets/commercial/Layer 2 (2).svg" alt="">
                  <img class="img-org" src={{robot?.imgUrl}} alt="">
              </div>
          </ng-template>
          <ng-template carouselSlide>
              <div class="robot-card">
                <img src="assets/commercial/Layer 2 (2).svg" alt="">
                <img class="img-org" src={{robot?.imgUrl}} alt="">
              </div>
          </ng-template>
          <ng-template carouselSlide>
              <div class="robot-card">
                <img src="assets/commercial/Layer 2 (2).svg" alt="">
                <img class="img-org" src={{robot?.imgUrl}} alt="">
              </div>
          </ng-template>
      </owl-carousel-o> -->
      <div class="robot-card">
        <!-- <img src="assets/commercial/Layer 2 (2).svg" alt=""> -->
        <img class="img-org" src={{robot?.imgUrl}} alt="">
    </div>
    </div>
    </div>
  </div>
</div>


