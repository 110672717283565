import { RatingModule } from 'ng-starrating';
import { RouterModule } from '@angular/router';
import { BrowserModule } from '@angular/platform-browser';
import { NgModule , APP_INITIALIZER , CUSTOM_ELEMENTS_SCHEMA  } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HomeComponent } from './home/home.component';
import { HeaderComponent } from './layouts/header/header.component';
import { FooterComponent } from './layouts/footer/footer.component';
import { OpenVideoComponent } from './home/open-video/open-video.component';
import { RobotSeriesComponent } from './home/robot-series/robot-series.component';
import { CarouselModule as scroll } from 'ngx-owl-carousel-o';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { BlogComponent } from './home/blog/blog.component';
import { ClientsComponent } from './home/clients/clients.component';
import { AboutUsComponent } from './about-us/about-us.component';
import { ContactUsComponent } from './contact-us/contact-us.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { NgxSpinnerModule } from 'ngx-spinner';
import { CommercialSeriesComponent } from './commercial-series/commercial-series.component';
import { CommercialComponent } from './commercial-series/commercial/commercial.component';
import { TranslationService } from './translation.service';
import { HashLocationStrategy, LocationStrategy } from '@angular/common';
import { LoaderComponent } from './layouts/loader/loader.component';
import {CarouselModule} from 'primeng/carousel';
import { BlogPageComponent } from './blog-page/blog-page.component';
import { ArticleComponent } from './article/article.component';


//set english language is the main lang in app
export function setupTranslateFactory(
  service: TranslationService): Function {
  return () =>  service.use('ar');
}

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    HeaderComponent,
    FooterComponent,
    OpenVideoComponent,
    RobotSeriesComponent,
    BlogComponent,
    ClientsComponent,
    AboutUsComponent,
    ContactUsComponent,
    CommercialSeriesComponent,
    CommercialComponent,
    LoaderComponent,
    BlogPageComponent,
    ArticleComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    RouterModule,
    BrowserAnimationsModule,
    RatingModule,
    FormsModule,
    scroll,
    ReactiveFormsModule,
    HttpClientModule,
    RouterModule.forRoot(
      [
        {path:"" , component:LoaderComponent},
      ],
      {scrollPositionRestoration: 'enabled'}
    ),
    NgxSpinnerModule,
    CarouselModule
  ],
  providers: [
    TranslationService,
    {
     provide: APP_INITIALIZER,
     useFactory: setupTranslateFactory,
     deps: [ TranslationService ],
     multi: true
   },
   {provide: LocationStrategy, useClass:  HashLocationStrategy},
   ],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class AppModule {}
