<ngx-spinner
  bdColor="rgba(255, 255, 255)"
  size="medium"
  color="#ED6B2D"
  z-index="999999999999999999999999999"
  template="<img src='../assets/loader/GIIIFFF.gif' />"
>
  <p style="font-size: 20px; color: white"></p>

</ngx-spinner>

<app-header></app-header>
<router-outlet></router-outlet>
<app-footer></app-footer>
