<div class="contact-us">
  <img class="img-background" src="../../assets/contact/contact.png" alt="">
    <div class="container">
        <div class="row">
            <div class="col-md-5">
                <div class={{translate.data.api.contact}}>
                    <h1>{{translate.data.Header.contact}}</h1>
                    <!-- <p>{{translate.data.contact.title}}</p> -->
                    <form #contactForm="ngForm" (ngSubmit)="onSubmit(contactForm.value)">
                        <input type="text" class="form-control" placeholder={{translate.data.contact.name}} name="name" ngModel>
                        <input type="email" class="form-control" placeholder={{translate.data.contact.email}} name="emailAddress"
                            ngModel>
                        <input type="text" class="form-control" placeholder={{translate.data.contact.subject}} name="subject" ngModel>
                        <textarea class="form-control" rows="4" placeholder={{translate.data.contact.mesg}} name="message"
                            ngModel></textarea>
                        <button class="btn c-btn" type="submit">{{translate.data.contact.send}}</button>
                    </form>
                </div>
            </div>
        </div>
    </div>
</div>
<ngx-spinner bdColor="rgba(0, 0, 0, 0.8)" size="medium" color="#eee" type="ball-clip-rotate-pulse" [fullScreen]="true"
    name="loader">
    <p style="color: white">
        برجاء الانتظار ... جارى اتمام العملية </p>
</ngx-spinner>
