import { DOCUMENT } from '@angular/common';
import { Component, Inject, OnInit } from '@angular/core';
import { RobotService } from './../../Services/robot.service';
import { TranslationService } from 'src/app/translation.service';
import {ActivatedRoute, Params, Router } from '@angular/router';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit {
  response:any;
  titles:any;
  linkNames:Array<any>;
    constructor(private route: Router,
      private routes: ActivatedRoute,
      public translate: TranslationService,
      @Inject(DOCUMENT) private document: Document ,
      private robotService: RobotService,
      private router: Router,) {}
  handleNavbar = () => {
    let element = this.document.getElementById('navBar');
    element?.classList.toggle('collapsee');
  };
  checkRoute() {

      this.route.navigate(['/']);

    let element = this.document.getElementById('navBar');
    element?.classList.toggle('collapsee');
  }
  ngOnInit(): void {
    this.getData();
  }
  getData(){
    this.robotService.getData().subscribe(
      (res) => {
        console.log('done');
        this.response = res;
        this.titles = this.response.seriesTypes;
        console.log("res",this.titles);
        this.linkNames = [...this.titles]
        for(let title of this.linkNames){
          let name = title.name_En.replace(/\s+/g, '');
          title.link = name;
          for(let subTitle of title.commericalSeries){
            let subName = subTitle.nameEn.replace(/\s+/g, '');
            subTitle.link = subName;
          }
        }
        console.log("new ",this.linkNames);

      },
      (error) => {
        console.log(error);
      }
    );
  }

  handleLang=()=>{
    // this.lang = data
    if(this.translate.data.api.language === 'En'){
      localStorage.setItem("languageApp", 'en');
    }else{
      localStorage.setItem("languageApp", 'ar');
    }
    // localStorage.setItem("languageApp", data);
    // console.log(localStorage.getItem('languageApp'));
    this.handleNavbar();
    location.reload();

  }

  goToContact(){
    this.router.navigate(["./../contact-us"],{relativeTo:this.routes});
    this.handleNavbar();
  }

}
