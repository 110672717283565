<div class="header">
    <div>
        <a class="" routerLink="" routerLinkActive="active"><img class="logo" src={{response?.companyMainDetails.imgUrl}}
                alt="icity"></a>
    </div>
    <div class="collapsation">
        <img src="../../../assets/header/Component 100 – 1.svg" alt="" (click)="handleNavbar()">
    </div>
    <div class="social-icons">
        <div class="icon">
            <a href={{response?.companyMainDetails.faceBookLink}} target="_blank"><i class="fab fa-facebook-f"></i></a>
        </div>
        <div class="icon">
            <a href={{response?.companyMainDetails.twitterLink}} target="_blank"><i class="fab fa-twitter"></i></a>
        </div>
        <div class="icon">
            <a href={{response?.companyMainDetails.instgramLink}} target="_blank"><i class="fab fa-instagram"></i></a>
        </div>
        <div class="icon">
            <a href={{response?.companyMainDetails.linkedInLink}} target="_blank"><i class="fab fa-linkedin-in"></i></a>
        </div>
    </div>
</div>
<div class="sidenav" id="navBar">
    <!-- <p>{{translate.data.Header.menu}}</p> -->
    <ul class="navbar-nav">
      <li class="nav-item header-title">{{translate.data.Header.menu}}</li>
        <li class="nav-item">
            <a class="nav-link" routerLink="/" routerLinkActive="active" (click)="handleNavbar()">
              {{translate.data.Header.home}}
            </a>
        </li>
        <li class="nav-item">
            <a class="nav-link" routerLink="/about-us" routerLinkActive="active" (click)="handleNavbar()">
              {{translate.data.Header.about}}
            </a>
        </li>

        <div *ngFor="let linkName of linkNames">
          <li class="nav-item dropdown">
            <a class="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
              {{linkName[translate.data.api.aboutFeatures]}}
            </a>
            <div class="dropdown-menu edit" aria-labelledby="navbarDropdown">
              <li class="dropdown-item" *ngFor="let series of linkName?.commericalSeries">
                <a class="nav-link" routerLink="{{'series/' +linkName?.link + '-' + series?.link}}"  routerLinkActive="active" (click)="handleNavbar()">
                       {{series[translate.data.Header.subMenu]}} <i class={{translate.data.classes.headerArrow}}></i>
                </a>
            </li>
              <!-- <a class="dropdown-item" href="#">Action</a>
              <a class="dropdown-item" href="#">Another action</a>
              <div class="dropdown-divider"></div>
              <a class="dropdown-item" href="#">Something else here</a> -->
            </div>
          </li>
          <!-- <li class="nav-item dropdown">
            <a class="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
              {{linkName[translate.data.api.aboutFeatures]}}
            </a>
            <ul class="dropdown-menu" aria-labelledby="navbarDropdown">
              <li class="nav-item" *ngFor="let series of linkName?.commericalSeries">
                <a class="nav-link" routerLink="{{'series/' +linkName?.link + '-' + series?.link}}"  routerLinkActive="active" (click)="handleNavbar()">
                       {{series[translate.data.Header.subMenu]}} <i class={{translate.data.classes.headerArrow}}></i>
                </a>
            </li>
            </ul>
          </li> -->
          <!-- <li class="nav-item" >
              <a class="nav-link" routerLink="{{'series/' +linkName?.link}}"  routerLinkActive="active" (click)="handleNavbar()">
                     {{linkName[translate.data.api.aboutFeatures]}}
              </a>
          </li>
          <li class="nav-item" *ngFor="let series of linkName?.commericalSeries">
            <a class="nav-link" routerLink="{{'series/' +linkName?.link + '-' + series?.link}}"  routerLinkActive="active" (click)="handleNavbar()">
                   {{series[translate.data.Header.subMenu]}} <i class={{translate.data.classes.headerArrow}}></i>
            </a>
        </li> -->
       </div>
        <li class="nav-item">
            <a class="nav-link"  routerLink="/blog" routerLinkActive="active" (click)="handleNavbar()">
              {{translate.data.Header.blog}}
            </a>
        </li>
        <li class="nav-item">
            <a class="nav-link" routerLink="/contact-us" routerLinkActive="active" (click)="goToContact()">
              {{translate.data.Header.contact}}
            </a>
        </li>
        <li class="nav-item language" (click)="handleLang()" >
            <p>{{translate.data.api.language}}</p>
        </li>
    </ul>
</div>
<nav class="navbar navbar-expand-lg  myNavBar mini-menu d-lg-none d-md-none">
    <a class="" routerLink="./home" routerLinkActive="active"><img class="logo" src="assets/header/logo.png" alt="icity"></a>
    <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNavDropdown"
        aria-controls="navbarNavDropdown" aria-expanded="false" aria-label="Toggle navigation">
        <span class="navbar-toggler-icon">
            <img src="../../../assets/header/Component 100 – 1.svg" alt="">
        </span>
    </button>
    <div class="collapse navbar-collapse" id="navbarNavDropdown">
        <div class="navItems">
            <ul class="navbar-nav me-auto mb-2 mb-lg-0 my-5">
              <li class="nav-item">
                <a class="nav-link" routerLink="/" routerLinkActive="active">
                  {{translate.data.Header.home}}
                </a>
            </li>
            <li class="nav-item">
                <a class="nav-link" routerLink="/about-us" routerLinkActive="active">
                  {{translate.data.Header.about}}
                </a>
            </li>

            <div *ngFor="let linkName of linkNames">
              <li class="nav-item dropdown">
                <a class="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                  {{linkName[translate.data.api.aboutFeatures]}}
                </a>
                <div class="dropdown-menu edit" aria-labelledby="navbarDropdown">
                  <li class="dropdown-item" *ngFor="let series of linkName?.commericalSeries">
                    <a class="nav-link" routerLink="{{'series/' +linkName?.link + '-' + series?.link}}"  routerLinkActive="active" (click)="handleNavbar()">
                           {{series[translate.data.Header.subMenu]}} <i class={{translate.data.classes.headerArrow}}></i>
                    </a>
                </li>

                </div>
              </li>

           </div>



            <li class="nav-item">
                <a class="nav-link" pageScroll href="#blog" (click)="checkRoute()">
                  {{translate.data.Header.blog}}
                </a>
            </li>
            <li class="nav-item">
                <a class="nav-link" routerLink="/contact-us" routerLinkActive="active" (click)="goToContact()">
                  {{translate.data.Header.contact}}
                </a>
            </li>
            <li class="nav-item language" (click)="handleLang()">
              <p>{{translate.data.api.language}}</p>
            </li>
          </ul>
        </div>
    </div>
</nav>
