import { Component, OnInit } from '@angular/core';
import {ActivatedRoute, Router } from '@angular/router';
import { TranslationService } from 'src/app/translation.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { RobotService } from './../Services/robot.service';

@Component({
  selector: 'app-blog-page',
  templateUrl: './blog-page.component.html',
  styleUrls: ['./blog-page.component.scss']
})
export class BlogPageComponent implements OnInit {
  data:any;
  blog:any;

  constructor(
    private spinner: NgxSpinnerService,
    private router: Router,
    private route: ActivatedRoute,
    public translate: TranslationService,
    private robotService: RobotService,
  ) { }

  ngOnInit(): void {
    this.spinner.show();
    this.robotService.getData().subscribe(
      (res) => {
        console.log('done');
        this.data = res;
        console.log("data",this.data);
        this.spinner.hide();
      },
      (error) => {
        console.log(error);
        this.spinner.hide();
      }
    );
  }

  handleArticle=(article:any)=>{
    this.router.navigate(["../article"], {relativeTo : this.route , queryParams: {data: JSON.stringify(article)}})
  }

}
