import { Component, OnInit , Input } from '@angular/core';
import { TranslationService } from 'src/app/translation.service';

@Component({
  selector: 'app-open-video',
  templateUrl: './open-video.component.html',
  styleUrls: ['./open-video.component.scss'],
})
export class OpenVideoComponent implements OnInit {
  video: any;
  videoBtn: any;
  className:any = 'titles '+ this.translate.data.classes.video;
  @Input() commercial:any;
  constructor(
    public translate: TranslationService,
  ) {}
  handleVideo = () => {
    if (this.video.paused) {
      this.videoBtn = 'assets/home/playBtn/pause-circle-regular.svg';
      this.video.play();
    } else {
      this.videoBtn = 'assets/home/playBtn/Path 44224 (1).svg';
      this.video.pause();
    }
  };
  ngOnInit(): void {
    this.videoBtn = 'assets/home/playBtn/Path 44224 (1).svg';
    this.video = document.getElementById('myVideo');
  }
}
