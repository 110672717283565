import { Component, OnInit } from '@angular/core';
import { OwlOptions } from 'ngx-owl-carousel-o';
import { RobotService } from './../../Services/robot.service';
import {ActivatedRoute, Params, Router } from '@angular/router';
import { TranslationService } from 'src/app/translation.service';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-commercial',
  templateUrl: './commercial.component.html',
  styleUrls: ['./commercial.component.scss']
})
export class CommercialComponent implements OnInit {
  response:any;
  data:any;
  commercial:any;
  title:any; // from url path
  subTitle:any;
  name:any;

  constructor(private robotService: RobotService,
    private router: Router,
    private route: ActivatedRoute,
    private spinner: NgxSpinnerService,
    public translate: TranslationService,) { }
  customOptions: OwlOptions = {
    loop: true,
    mouseDrag: false,
    touchDrag: false,
    pullDrag: false,
    dots: true,
    navSpeed: 700,
    navText: ['<', '>'],
    responsive: {
      0: {
        items: 1,
      },
      400: {
        items: 2,
      },
      740: {
        items: 2,
      },
      940: {
        items: 2,
      },
    },
    nav: true,

  };

  ngOnInit(): void {
    window.onbeforeunload = function() {window.scrollTo(0,0);}
    this.spinner.show();
    this.route.params.subscribe(
      (params: Params) => {
        this.title = params['data'].split("-")[1];
        console.log("title",this.title);
        console.log("sub",);
        this.subTitle = params['data'].split("-")[0];
        console.log("subtitle",this.subTitle);

        this.getData();
      }
    )

  }

  getData(){
    this.robotService.getData().subscribe(
      (res) => {
        console.log('done');
        this.response = res;
        this.commercial = this.response.seriesTypes;
        console.log("data",this.commercial);
        for(let x of this.commercial){
          let name = x.name_En.replace(/\s+/g, '');
          console.log("name",name);
          if(this.subTitle === name){
            this.name = this.translate.is_arabic ? x.name_Ar : x.name_En;
            for(let robot of x.commericalSeries){
              let subName = robot.nameEn.replace(/\s+/g, '');
              if(this.title === subName ){
                this.data = robot;
              }else if (this.title == undefined){
                this.data = x.commericalSeries[0];
              }
            }
          }
        }
        console.log("data route",this.data);
        console.log("name name", this.name);

        this.spinner.hide();

      },
      (error) => {
        console.log(error);
        this.spinner.hide();
      }
    );
  }

  goToContact(){
    this.router.navigate(["../../contact-us"],{relativeTo:this.route});
  }



}
