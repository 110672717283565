import { Component, OnInit } from '@angular/core';
import { RobotService } from './../Services/robot.service';
import {ActivatedRoute, Params, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {

  response:any;
  commercial:any;

  constructor(
    private robotService: RobotService,
    private router: Router,
    private route: ActivatedRoute,
    private spinner: NgxSpinnerService,
  ) { }

  ngOnInit(): void {
    // this.spinner.show();
      this.robotService.getData().subscribe(
        (res) => {

          console.log('done');
          // this.response = res;
          this.commercial = res;
          console.log("data",this.commercial);
          this.spinner.hide();
        },
        (error) => {
          this.spinner.hide();
          console.log(error);

        }
      );

  }

}
