import { Component, OnInit , Input } from '@angular/core';
import { OwlOptions } from 'ngx-owl-carousel-o';
import { TranslationService } from 'src/app/translation.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-robot-series',
  templateUrl: './robot-series.component.html',
  styleUrls: ['./robot-series.component.scss'],
})
export class RobotSeriesComponent implements OnInit {
  @Input() commercial:any;
  constructor(public translate: TranslationService,private route : Router) {}
  customOptions: OwlOptions = {
    loop: true,
    mouseDrag: false,
    touchDrag: false,
    pullDrag: false,
    dots: true,
    navSpeed: 700,
    navText: ['<', '>'],
    responsive: {
      0: {
        items: 1,
      },
      400: {
        items: 1,
      },
      740: {
        items: 2,
      },
      940: {
        items: 2,
      },
    },
    nav: true,

  };
  ngOnInit(): void {}

  goToSeries(page:any){
    let link = page.replace(/\s+/g, '');
    this.route.navigate(['/series/'+ link])
  }
}
