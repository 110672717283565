import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class TranslationService {


  data: any = {};

  langVar :any ;

  is_arabic: boolean = false;

  constructor(private http: HttpClient) {}

  use(lang: string): Promise<{}> {

    const gloalLanguageApp = localStorage.getItem('languageApp');

    if(gloalLanguageApp) {
      lang = gloalLanguageApp;
    } else {
      lang = 'ar';
    }

    if(lang == 'ar'){
      this.langVar = 'ar';
    }else{
      this.langVar = 'en'
    }
     if(lang == 'ar') {
      document.body.className = "body-ar";
      this.is_arabic = true;
    } else {
      document.body.className = "body";
      this.is_arabic = false;
    }

    return new Promise<{}>((resolve, reject) => {
      const langPath = `assets/i18n/${lang || 'en'}.json`;
      this.http.get<{}>(langPath).subscribe(
        translation => {
          this.data = Object.assign({}, translation || {});
          resolve(this.data);
        },
        error => {
          this.data = {};
          resolve(this.data);
        }
      );
    });
  }
}
