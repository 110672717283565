import { RobotService } from './../../Services/robot.service';
import { Component, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { NgxSpinnerService } from 'ngx-spinner';
import { Router } from '@angular/router';
import { TranslationService } from 'src/app/translation.service';
@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
})
export class FooterComponent implements OnInit {
  response:any;
  titles:any;
  linkNames:Array<any>;
  response1:any;
  constructor(
    private robotService: RobotService,
    private spinner: NgxSpinnerService,
    private route: Router,
    public translate: TranslationService,
  ) {}
  @ViewChild('contactForm') contForm: NgForm;
  onSubmit(contactForm: any) {
    this.spinner.show('loader');
    this.robotService.contactUs(contactForm).subscribe(
      (res) => {
        console.log('done');
        this.spinner.hide('loader');
        this.contForm.reset();
      },
      (error) => {
        this.spinner.hide('loader');
        console.log(error);
      }
    );
  }
  ngOnInit(): void {
    this.getData();
    this.robotService.getData().subscribe(
      (res) => {
        console.log('done');
        this.response = res;
        console.log("res",this.response);

      },
      (error) => {
        console.log(error);
      }
    );
  }

  checkRoute() {

    this.route.navigate(['/']);

}
  getData(){
    this.robotService.getData().subscribe(
      (res) => {
        console.log('done');
        this.response1 = res;
        this.titles = this.response1.seriesTypes;
        console.log("res",this.titles);
        this.linkNames = [...this.titles]
        for(let title of this.linkNames){
          let name = title.name_En.replace(/\s+/g, '');
          title.link = name;
          for(let subTitle of title.commericalSeries){
            let subName = subTitle.nameEn.replace(/\s+/g, '');
            subTitle.link = subName;
          }
        }
        console.log("new ",this.linkNames);

      },
      (error) => {
        console.log(error);
      }
    );
  }
}
