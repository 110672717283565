<div class="blog">
  <div class="header"><h2>{{translate.data.Home.blog.title}}</h2></div>
  <img class="lines" src="../../assets/Path 45141.svg" alt="">
  <!-- <div class="header"> -->
    <!-- <div class="contt"><h2>{{translate.data.Home.blog.title}}</h2></div></div> -->
  <div class="row padd">
    <div class="col-lg-4 col-md-6 col-sm-12 cont" *ngFor = "let article of data?.blog">
      <div class="blog-card card" (click)="handleArticle(article)">
        <img  src={{article?.imgUrl}} alt="">
        <div class="card-body">
            <!-- <div class="line"></div> -->
            <h5>{{article[translate.data.Home.blog.articleTitle]}}</h5>
            <p>{{article[translate.data.Home.blog.articleDesc].slice(0, 100)}}</p>
        </div>
    </div>
    </div>
  </div>
</div>
