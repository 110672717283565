import { Robot } from './../Models/robot';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root',
})
export class RobotService {
  constructor(private http: HttpClient) {}
  apiUrl = 'https://icityrobots.icitybackend.com/api/';

  contactUs(Robot: Robot) {
    return this.http.post(this.apiUrl +`v1/ContactUsRequests`, Robot);
  }

  // get data
  getData(){
    return this.http.get(this.apiUrl + 'v1/Home')
  }
}
