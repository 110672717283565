<div class="article">
  <div class="header">
    <h2>{{translate.langVar == 'ar' ? dataParams?.mainTitle_Ar : dataParams?.mainTitle_En}}</h2>
  </div>
  <img class="lines" src="../../assets/Path 45141.svg" alt="">
  <!-- <div class="header">
    <div class="cont">
      <h2>{{translate.langVar == 'ar' ? dataParams?.mainTitle_Ar : dataParams?.mainTitle_En}}</h2>
    </div>
  </div> -->
  <div class="bodyy">
    <div class="container">
      <div class="row">
        <div class=" edit col-12 d-flex flex-wrap">
          <div class="col-lg-4 col-md-6 col-12">
            <img src={{dataParams?.imgUrl}} alt="">
          </div>
          <div class="col-lg-8 col-md-6 col-12">
            <p>{{translate.langVar == 'ar' ? dataParams?.description_Ar : dataParams?.description_En}}</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
