import { Component, OnInit , Input} from '@angular/core';
import { TranslationService } from 'src/app/translation.service';
import { RobotService } from './../../Services/robot.service';
import {ActivatedRoute, Params, Router } from '@angular/router';

@Component({
  selector: 'app-blog',
  templateUrl: './blog.component.html',
  styleUrls: ['./blog.component.scss']
})
export class BlogComponent implements OnInit {
 commercial:any;
  blog:Array<any> = [];
  article1:any;

  constructor(
    public translate: TranslationService,
    private robotService: RobotService,
    private router: Router,
    private routes: ActivatedRoute,
  ) { }

  ngOnInit(): void {
    this.robotService.getData().subscribe(
      (res) => {
        console.log('done');
        // this.response = res;
        this.commercial = res;
        // this.blog= this.commercial.blog
        // for(let i = 1 ; i <3 ; i++){
        //   this.blog.push(this.commercial.blog[i])
        // }
        this.blog = this.commercial.blog.map((article:any)=>({...article,
          descriptionAr : article.description_Ar.slice(0, 60) + ".....",
          descriptionEn : article.description_En.slice(0, 60) + "....."}))
        console.log("data",this.commercial);
      },
      (error) => {
        console.log(error);
      }
    );
    if(this.commercial){

    }

    console.log("blog",this.blog);

  }

  goToContact(){
    this.router.navigate(["./../blog"],{relativeTo:this.routes});
  }
  handleArticle=(article:any)=>{
    this.router.navigate(["../article"], {relativeTo : this.routes , queryParams: {data: JSON.stringify(article)}})
  }

}
