import { Component, OnInit  } from '@angular/core';
import {ActivatedRoute, Router , Params } from '@angular/router';
import { TranslationService } from 'src/app/translation.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { RobotService } from './../Services/robot.service';

@Component({
  selector: 'app-article',
  templateUrl: './article.component.html',
  styleUrls: ['./article.component.scss']
})
export class ArticleComponent implements OnInit {
  dataParams:any;

  constructor(
    private spinner: NgxSpinnerService,
    private router: Router,
    private route: ActivatedRoute,
    public translate: TranslationService,
    private robotService: RobotService,
  ) { }

  ngOnInit(): void {
    this.route.queryParams.subscribe(
      (params: Params) => {
        this.dataParams = JSON.parse(params['data']);
    })
  }

}
