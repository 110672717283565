<div class="robot-series">
  <div class="header"><h1>{{translate.data.Home.robot.seriesRobot}}</h1></div>
  <img class="lines" src="../../assets/Path 45141.svg" alt="">
    <div class="container">
        <div class="row">
            <!-- <div class="offset-md-4"></div> -->
            <!-- <div class="col-12 padd">

            </div> -->
            <!-- <div class="offset-md-4"></div> -->
        </div>
        <div class="row">
            <!-- <owl-carousel-o [options]="customOptions">
                <ng-template carouselSlide *ngFor="let robot of commercial?.seriesTypes">

                </ng-template>


            </owl-carousel-o> -->
            <div class="col-lg-4 col-md-6 col-12" *ngFor="let robot of commercial?.seriesTypes">
              <div class="robot-card">
                <!-- <img class="fram" src="../../../assets/Layer 2 (2).svg" alt=""> -->
                <img class="img" src={{robot?.imgUrl}} alt="">
                <h6 class={{translate.data.api.h}}>{{robot[translate.data.api.aboutFeatures]}}</h6>
                <p class="p-scroll">{{robot[translate.data.api.description]}}</p>
                <button (click)= "goToSeries(robot?.name_En)" class="btn b-btn">{{translate.data.Home.blog.more}}</button>
              </div>
            </div>
        </div>
    </div>
</div>
