import { RobotService } from './../Services/robot.service';
import { Component, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { NgxSpinnerService } from 'ngx-spinner';
import { TranslationService } from 'src/app/translation.service';

@Component({
  selector: 'app-contact-us',
  templateUrl: './contact-us.component.html',
  styleUrls: ['./contact-us.component.scss'],
})
export class ContactUsComponent implements OnInit {
  constructor(private robotService: RobotService,
    private spinner:NgxSpinnerService,
    public translate: TranslationService,) {}
  @ViewChild ('contactForm') contForm:NgForm
  onSubmit(contactForm: any) {
    this.spinner.show('loader')
    this.robotService.contactUs(contactForm).subscribe(
      (res) => {
        console.log('done');
        this.spinner.hide('loader');
        this.contForm.reset()
      },
      (error) => {
        this.spinner.hide('loader');
        console.log(error);
      }
    );
  }
  ngOnInit(): void {
    // this.spinner.show();
    // setTimeout(() => {
    //   this.spinner.hide();
    // }, 4000);
  }
}
