import { Component, OnInit } from '@angular/core';
import { RobotService } from './../Services/robot.service';
import {ActivatedRoute, Router } from '@angular/router';
import { TranslationService } from 'src/app/translation.service';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-about-us',
  templateUrl: './about-us.component.html',
  styleUrls: ['./about-us.component.scss']
})
export class AboutUsComponent implements OnInit {

  data:any;

  constructor(
    private spinner: NgxSpinnerService,
    private robotService: RobotService,
    private router: Router,
    private route: ActivatedRoute,
    public translate: TranslationService,
  ) { }

  ngOnInit(): void {
    this.spinner.show();
    this.robotService.getData().subscribe(
      (res) => {
        console.log('done');
        this.data = res;
        console.log("data",this.data);
        this.spinner.hide();
      },
      (error) => {
        console.log(error);
        this.spinner.hide();
      }
    );
  }

}
